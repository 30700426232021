.table-costum {
    .sortable {
        position: relative;
        cursor: pointer;
    }
    .sortable::before {
        position: absolute;
        right: .5rem;
        left: auto;
        content: "\F0360";
        font-family: "Material Design Icons";
        font-size: 1rem;
        top: 12px;
        opacity: 0.5;
    }
    .sortable::after {
        position: absolute;
        right: .5em;
        left: auto;
        content: "\F035D";
        font-family: "Material Design Icons";
        top: 18px;
        opacity: 0.5;
        font-size: 1rem;
    }
    th {
        padding-left: 0.95rem;
        padding-right: 30px;
        white-space: nowrap;
    }
    .withpre {        
        white-space: pre;
    }
}

.dashboard {
    .table tbody tr td .dash-kegiatanseminar {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        .foto img{
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }
        .kegiatan { 
            margin-left: 15px;
        }
        .materi {
            color : rgb(122, 122, 122)
        }
    }
}